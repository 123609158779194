import React, { useMemo } from "react";
import { Redirect } from "react-router-dom";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { useLoginProvider } from "../../Login/LoginContext";

interface OnboardingCheckComponentProps {
  children: React.ReactNode;
}

export default function OnboardingCheckComponent({
  children,
}: OnboardingCheckComponentProps): React.JSX.Element {
  const { signedInUser, loading } = useUserApiClient();
  const { email } = useLoginProvider();

  const hasCompletedOnboarding = useMemo(() => {
    return (
      signedInUser?.firstName &&
      signedInUser?.lastName &&
      signedInUser?.userName
    );
  }, [signedInUser]);

  if (!loading && signedInUser && !hasCompletedOnboarding) {
    if (email) {
      return <Redirect to="/finish-setup" />;
    }
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
}
