import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserApiClient } from "../../hooks/useUserApiClient";
import { OnboardingDetails } from "../../types/auth";
import styles from "../Signup/signup.module.css";
import ErrorComponent from "../common_components/ErrorComponent";
import FooterComponent from "../common_components/FooterComponent";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";
import HeaderComponent from "../common_components/MainHeaderComponent";
import { uploadPublicAsset } from "../../rest-clients/FileService";
import OnboardingFormComponent from "./OnboardingFormComponent";
import { useLoginProvider } from "../Login/LoginContext";

export default function OnboardingView(): React.JSX.Element {
  const { email } = useLoginProvider();
  const [onboardingDetails, setOnboardingDetails] = useState<OnboardingDetails>(
    {
      firstName: "",
      lastName: "",
      userName: "",
      profilePicture: undefined,
      email: email ?? "",
    }
  );
  const [error, setError] = useState("");
  const { updateUserProfile, loading, signedInUser } = useUserApiClient();
  const history = useHistory();

  useEffect(() => {
    onboardingDetails.email = email ?? "";
  }, [email]);

  useEffect(() => {
    if (
      signedInUser?.firstName &&
      signedInUser?.lastName &&
      signedInUser?.userName
    ) {
      history.push("/menu");
    }
  }, [signedInUser]);

  const updateProfile = React.useCallback(async () => {
    let url;
    if (onboardingDetails.profilePicture) {
      const data = new FormData();
      data.append("file", onboardingDetails.profilePicture);
      const upload = await uploadPublicAsset(data)
        .then((data) => data)
        .catch((e) => ({ success: false, error: e, assetUrl: "" }));
      if (!upload.success) {
        setError(upload.error);
        return;
      } else {
        url = upload.assetUrl;
      }
    }
    updateUserProfile({ ...onboardingDetails, profilePictureUrl: url })
      .then(() => history.push("/menu"))
      .catch((error) => {
        if (error instanceof Error) {
          return setError(error.message);
        }
        return setError("Error occurred while signing up");
      });
  }, [onboardingDetails, history, updateUserProfile]);

  const headerElements = (
    <>
      <a href="/logout" className={styles.loginButton}>
        Log Out
      </a>
    </>
  );

  return (
    <div className={styles.container + " " + styles.bgStandard}>
      {loading ? (
        <LoadingComponentFullScreen />
      ) : (
        <>
          <HeaderComponent headerElements={headerElements} />
          <div className={styles.innerSignupDiv}>
            <div className={styles.error}>
              {error && (
                <ErrorComponent
                  title="Onboarding Failed"
                  close={() => setError("")}
                >
                  {error}
                </ErrorComponent>
              )}
            </div>
            <OnboardingFormComponent
              onboardingDetails={onboardingDetails}
              setOnboardingDetails={setOnboardingDetails}
              onboard={() => {
                void updateProfile();
              }}
            />
          </div>
          <FooterComponent />
        </>
      )}
    </div>
  );
}
