import React, { useMemo } from "react";

import styles from "./Sidebar.module.css";
import { WorkspaceSideBar } from "../../common_components/ChamIDE/components/DirectoryTree/WorkspaceSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "@mui/material";
import { ClarificationsView } from "../../Lesson/components/Clarifications";
import { SidebarViewState } from "../../common_components/LessonLayout/types";
import { useViewContext } from "../../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import {
  DeviceType,
  useResponsiveness,
} from "../../../hooks/useResponsiveness";

interface SideBarProps {
  setError: (error: string) => void;
}

export const SideBar = ({ setError }: SideBarProps): JSX.Element => {
  const {
    currentView,
    setCurrentView,
    currentSidebarView,
    setCurrentSidebarView,
  } = useViewContext();
  const { deviceType } = useResponsiveness();

  const isSidebarOpen = useMemo(
    () => currentSidebarView !== SidebarViewState.None,
    [currentSidebarView]
  );

  const tabs = React.useMemo(
    () => [
      {
        icon: faMessage,
        onClick: () => {
          setCurrentSidebarView(
            currentSidebarView === SidebarViewState.Clarifications
              ? SidebarViewState.None
              : SidebarViewState.Clarifications
          );
        },
        active: currentSidebarView === SidebarViewState.Clarifications,
        tooltip: "Clarifications",
        mobileOnly: false,
      },
    ],
    [
      currentView,
      setCurrentView,
      currentSidebarView,
      setCurrentSidebarView,
      deviceType,
    ]
  );

  return (
    <div className={styles.sideBarContainer}>
      <div className={styles.sideTab}>
        {tabs.map((tab, index) => (
          <Tooltip
            hidden={tab.mobileOnly && deviceType === DeviceType.Desktop}
            key={index}
            title={tab.tooltip}
          >
            <div
              className={tab.active ? styles.activeTab : styles.tab}
              onClick={tab.onClick}
            >
              <FontAwesomeIcon icon={tab.icon} />
            </div>
          </Tooltip>
        ))}
      </div>
      {isSidebarOpen && (
        <div className={styles.sideBar}>
          {currentSidebarView === SidebarViewState.File && (
            <WorkspaceSideBar setError={setError} />
          )}
          {currentSidebarView === SidebarViewState.Clarifications && (
            <ClarificationsView />
          )}
        </div>
      )}
    </div>
  );
};
