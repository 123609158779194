import { useMutation } from "@apollo/client";
import {
  RESET_PASSWORD_MUTATION,
  SIGN_IN_MUTATION,
  SIGN_OUT_MUTATION,
  REQUEST_PASSWORD_RESET_MUTATION,
} from "../graphql";
import { Clients } from "../index";
import React, { useState } from "react";

interface SignInResponse {
  success: boolean;
  error: string;
}

interface ResetPasswordData {
  token: string;
  newPassword: string;
}

interface UseAuthApiClient {
  loading: boolean;
  signIn: (
    email: string,
    password: string,
    domain: string
  ) => Promise<SignInResponse>;
  signOut: () => Promise<void>;
  resetPassword: (data: ResetPasswordData) => Promise<void>;
  requestPasswordReset: (email: string) => Promise<void>;
}

function useAuthApiClient(): UseAuthApiClient {
  const [loading, setLoading] = useState(false);
  const [signInMutation] = useMutation(SIGN_IN_MUTATION, {
    client: Clients.AUTH,
  });
  const [signOutMutation] = useMutation(SIGN_OUT_MUTATION, {
    client: Clients.AUTH,
  });
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION, {
    client: Clients.AUTH,
  });
  const [requestPasswordResetMutation] = useMutation(
    REQUEST_PASSWORD_RESET_MUTATION,
    {
      client: Clients.AUTH,
    }
  );

  const signIn = React.useCallback(
    async (
      email: string,
      password: string,
      domain: string
    ): Promise<SignInResponse> => {
      setLoading(true);
      return await signInMutation({
        variables: {
          email,
          password,
          domain,
        },
      })
        .then((result) => {
          return {
            success: result.data?.signIn.isLoggedIn ?? false,
            error: result.data?.signIn.error?.message ?? "No error message",
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const signOut = React.useCallback(async (): Promise<void> => {
    setLoading(true);
    await signOutMutation();
    setLoading(false);
  }, []);

  const resetPassword = React.useCallback(
    async ({ token, newPassword }: ResetPasswordData): Promise<void> => {
      setLoading(true);
      return await resetPasswordMutation({
        variables: {
          token,
          newPassword,
        },
      })
        .then((result) => {
          if (result.data?.resetPassword.error) {
            throw new Error(result.data.resetPassword.error.message);
          }
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const requestPasswordReset = React.useCallback(
    async (email: string): Promise<void> => {
      setLoading(true);
      await requestPasswordResetMutation({
        variables: {
          email,
        },
      })
        .then((result) => {
          if (result.data?.requestPasswordReset?.error) {
            throw new Error(result.data.requestPasswordReset.error.message);
          }
        })
        .finally(() => setLoading(false));
    },
    []
  );

  return {
    loading,
    signIn,
    signOut,
    requestPasswordReset,
    resetPassword,
  };
}

export default useAuthApiClient;
