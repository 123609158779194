import React from "react";
import CheckLoginComponent from "../CheckLoginComponent";
import HeaderComponent from "../DashboardHeaderComponent";
import OnboardingCheckComponent from "../OnboardingCheckComponent/OnboardingCheckComponent";
import SideBarComponent from "../SideBarComponent";
import styles from "./DashboardComponent.module.css";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

interface DashboardComponentProps {
  children: React.ReactNode;
  headerText?: string;
}

export default function DashboardComponent(
  props: DashboardComponentProps,
): JSX.Element {
  const { organization } = useUserApiClient();
  return (
    <OnboardingCheckComponent>
      <CheckLoginComponent>
        <div className={styles.container}>
          <HeaderComponent headerText={props.headerText} />
          <SideBarComponent />
          <div className={styles.mainWindow}>
            {props.children}
            <div
              className={styles.copyright}
              style={{ color: organization?.primaryColor }}
            >
              Copyright 2024 {organization?.name}. Powered by BuildQL
            </div>
          </div>
        </div>
      </CheckLoginComponent>
    </OnboardingCheckComponent>
  );
}
