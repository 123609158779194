import React, { useEffect, useState } from "react";
import { LessonProvider } from "../common_components/LessonContext/LessonContext";
import useLessonApiClient from "../../hooks/useLessonApiClient";
import DashboardHeaderProvider from "../common_components/DashboardHeaderComponent/DashboardHeaderContext";
import { ClarificationChatProvider } from "../common_components/ChamIDE/contexts/ClarificationChatContext/ClarificationChatContext";
import { PopoverProvider } from "../common_components/ChamIDE/contexts/PopoverContext/PopoverContext";
import { ViewContextProvider } from "../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import CheckLoginComponent from "../common_components/CheckLoginComponent";
import CheckSubscriptionComponent from "../common_components/CheckSubscriptionComponent";
import { ChatView } from "../Lesson/components/Clarifications/ChatView";
import { ClarificationWithMessage } from "../../types/clarifications";
import useAILessonCreatorClient from "../../hooks/useAILessonCreatorClient";
import { SidebarViewState } from "../common_components/LessonLayout/types";
import Layout from "./Layout/Layout";
import { DeviceType, useResponsiveness } from "../../hooks/useResponsiveness";

export default function ChatBotView(): React.JSX.Element {
  const { fetchLessonsByTitle } = useLessonApiClient();
  const { getClarification, generateSectionContentFromPrompt } =
    useAILessonCreatorClient();
  const [codeChatId, setCodeChatId] = React.useState<string>();
  const [activeChatId, setActiveChatId] = React.useState<string>();
  const [fetchClarificationsTrigger, setFetchClarificationsTrigger] =
    React.useState<number>(0);
  const [lessonId, setLessonId] = useState<string>("");
  const CHATBOT_LESSON_TITLE =
    "Chatbot for interacting with the entire organization documentation.";
  const { deviceType } = useResponsiveness();

  const [clarificationData, setClarificationData] = React.useState<{
    loading: boolean;
    data?: ClarificationWithMessage;
  }>({
    loading: true,
    data: undefined,
  });

  useEffect(() => {
    const getClarificationEffect = async (): Promise<void> => {
      setClarificationData({ ...clarificationData, loading: true });
      if (activeChatId) {
        const res = await getClarification(activeChatId);
        setClarificationData({ loading: false, data: res });
      } else {
        setClarificationData({ loading: false, data: undefined });
      }
    };
    if (activeChatId !== "new") {
      void getClarificationEffect();
    } else {
      setClarificationData({ loading: false, data: undefined });
    }
  }, [activeChatId]);

  useEffect(() => {
    fetchLessonsByTitle(CHATBOT_LESSON_TITLE)
      .then(async (result) => {
        let lessonId: string;
        if (result.length > 0) {
          lessonId = result[0].id;
        } else {
          lessonId =
            await generateSectionContentFromPrompt(CHATBOT_LESSON_TITLE);
        }
        setLessonId(lessonId);
      })
      .catch((_error) => {
        setLessonId("");
      });
  }, []);

  return (
    <CheckLoginComponent>
      <CheckSubscriptionComponent>
        <LessonProvider lessonId={lessonId} lessonAuthorId={""}>
          <DashboardHeaderProvider isTransparent={true}>
            <ClarificationChatProvider
              setCodeChatId={setCodeChatId}
              activeChatId={activeChatId}
              setActiveChatId={(chatId?: string) => setActiveChatId(chatId)}
              codeChatId={codeChatId}
              fetchClarificationsTrigger={fetchClarificationsTrigger}
              setFetchClarificationsTrigger={setFetchClarificationsTrigger}
            >
              <PopoverProvider>
                <ViewContextProvider
                  currentSidebarView={
                    deviceType === DeviceType.Desktop
                      ? SidebarViewState.Clarifications
                      : SidebarViewState.None
                  }
                >
                  <Layout>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        background: "#161817",
                      }}
                    >
                      <ChatView
                        chatId={activeChatId ?? "new"}
                        clarification={clarificationData.data}
                        isClarificationLoading={clarificationData.loading}
                      />
                    </div>
                  </Layout>
                </ViewContextProvider>
              </PopoverProvider>
            </ClarificationChatProvider>
          </DashboardHeaderProvider>
        </LessonProvider>
      </CheckSubscriptionComponent>
    </CheckLoginComponent>
  );
}
