import React from "react";
import styles from "./submit.module.css";
import { useUserApiClient } from "../../../../hooks/useUserApiClient";

interface SubmitProps {
  className: string;
}

export default function Submit(
  props: SubmitProps &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
): JSX.Element {
  const { organization } = useUserApiClient();
  return (
    <input
      {...props}
      className={styles.submit + " " + props.className}
      style={{ backgroundColor: organization?.primaryColor }}
    />
  );
}
