import React, { useMemo } from "react";
import { User } from "../../../../types/auth";
import { PaginatedLesson } from "../../../../types/lesson";
import GridComponent from "../../../common_components/GridComponent";
import styles from "./content.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFilter,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import LessonCard from "../../../common_components/LessonCard";
import { Organization } from "../../../../types/organization";

interface SimpleTabsProps {
  organization?: Organization;
  paginatedLessons: PaginatedLesson;
  user: User | undefined;
  signedInUser: User | undefined;
  setCurrentPage: (page: number) => void;
  currentPage: number;
}

export default function SimpleTabs({
  organization,
  paginatedLessons,
  signedInUser,
  user,
  setCurrentPage,
  currentPage,
}: SimpleTabsProps): JSX.Element {
  const [searchTerm, setSearchTerm] = React.useState("");
  const isProfileOwner = useMemo(() => {
    return user && signedInUser && user.id === signedInUser.id;
  }, [user, signedInUser]);
  const [currentTab, setCurrentTab] = React.useState(0);
  const lessonsToShow = currentTab === 0 ? paginatedLessons.lessons : [];
  const filteredLessons = lessonsToShow.filter((lesson) =>
    lesson.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <div className={styles.profileNav}>
        <div className={styles.userLessonNav}>
          <div className={styles.left}>
            <div
              className={`${styles.myLessonsTab} ${
                currentTab === 0 ? styles.active : ""
              }`}
              style={{
                color: currentTab === 0 ? organization?.primaryColor : "",
              }}
              onClick={() => setCurrentTab(0)}
            >
              Created Lessons ({paginatedLessons.totalElements})
            </div>
            {isProfileOwner && (
              <div
                className={`${styles.createdLessonsTab} ${
                  currentTab === 1 ? styles.active : ""
                }`}
                style={{
                  color: currentTab === 1 ? organization?.primaryColor : "",
                }}
                onClick={() => setCurrentTab(1)}
              >
                My Lessons ({0})
              </div>
            )}
          </div>
          <div className={styles.right}>
            <div
              className={styles.searchContainer}
              style={{ color: organization?.primaryColor }}
            >
              <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
              <input
                type="text"
                name="search"
                placeholder="Search for lessons"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchTerm(searchTerm);
                  }
                }}
              />
              <FontAwesomeIcon icon={faFilter} className={styles.filterIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.lessons}>
        <GridComponent
          boxes={filteredLessons.map((lesson, index) => (
            <LessonCard
              key={index}
              lesson={lesson}
              clickUrl={`/lesson/${lesson.id}`}
            />
          ))}
        />
        <div className={styles.paginationButtons}>
          <button
            disabled={currentPage <= 0}
            onClick={() => setCurrentPage(currentPage - 1)}
            className={styles.previousButton}
            style={{
              color: organization?.primaryColor,
              borderColor: organization?.primaryColor,
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            Previous
          </button>
          <button
            disabled={!paginatedLessons.hasNext}
            onClick={() => setCurrentPage(currentPage + 1)}
            className={styles.nextButton}
            style={{
              color: organization?.primaryColor,
              borderColor: organization?.primaryColor,
            }}
          >
            Next
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
    </div>
  );
}
