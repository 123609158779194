import React from "react";
import { SidebarViewState, ViewState } from "../../../LessonLayout/types";

export interface ViewContextData {
  currentView: ViewState;
  setCurrentView: (view: ViewState) => void;
  currentSidebarView: SidebarViewState;
  setCurrentSidebarView: (view: SidebarViewState) => void;
}
export const ViewContext = React.createContext<ViewContextData>({
  currentView: ViewState.Lesson,
  setCurrentView: () => undefined,
  currentSidebarView: SidebarViewState.None,
  setCurrentSidebarView: () => undefined,
});

interface ViewProviderProps {
  children: React.ReactNode;
  currentView?: ViewState;
  currentSidebarView?: SidebarViewState;
}

export function ViewContextProvider({
  children,
  currentView: defaultCurrentView = ViewState.Lesson,
  currentSidebarView: defaultCurrentSidebarView = SidebarViewState.None,
}: ViewProviderProps): React.ReactNode {
  const [currentView, setCurrentView] =
    React.useState<ViewState>(defaultCurrentView);
  const [currentSidebarView, setCurrentSidebarView] =
    React.useState<SidebarViewState>(defaultCurrentSidebarView);

  return (
    <ViewContext.Provider
      value={{
        currentView,
        setCurrentView,
        currentSidebarView,
        setCurrentSidebarView,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
}

export function useViewContext(): ViewContextData {
  const { currentView, setCurrentView } = React.useContext(ViewContext);
  const { currentSidebarView, setCurrentSidebarView } =
    React.useContext(ViewContext);
  return {
    currentView,
    setCurrentView,
    currentSidebarView,
    setCurrentSidebarView,
  };
}
