import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Status } from "../__generated__/graphql";
import {
  CANCEL_SUBSCRIPTION_MUTATION,
  CREATE_CHECKOUT_SESSION_MUTATION,
  CREATE_CUSTOMER_PORTAL_SESSION_MUTATION,
  CREATE_USER_MUTATION,
  FETCH_ORGANIZATION_BY_DOMAIN_QUERY,
  FETCH_SIGNED_IN_USER_QUERY,
  FETCH_USER_QUERY,
  GET_CHECKOUT_SESSION,
  GET_USER_SUBSCRIPTION_STATUS,
  UPDATE_USER_PROFILE_MUTATION,
} from "../graphql";
import { Clients } from "../index";
import {
  CheckoutSession,
  CheckoutSessionStatus,
  OnboardingDetailsWithProfilePictureUrl,
  SignupDetails,
  SubscriptionStatus,
  User,
} from "../types/auth";
import { Organization } from "../types/organization";

interface UseUserApiClient {
  loading: boolean;
  signedInUser: User | undefined;
  organization: Organization | undefined;
  fetchUserById: (userId: string, organizationId: string) => Promise<User>;
  fetchOrganizationByDomain: (domain: string) => Promise<Organization>;
  getCheckoutSessionStatus: (
    sessionId: string,
  ) => Promise<CheckoutSessionStatus>;
  createCheckoutSession: () => Promise<CheckoutSession>;
  createCustomerPortalSession: () => Promise<string>;
  getUserSubscriptionStatus: (
    organizationId: string,
  ) => Promise<SubscriptionStatus>;
  cancelSubscription: () => Promise<boolean>;
  createUser: (signupDetails: SignupDetails) => Promise<User>;
  updateUserProfile: (
    onboardingDetails: OnboardingDetailsWithProfilePictureUrl,
  ) => Promise<User>;
}

export function useUserApiClient(): UseUserApiClient {
  const [fetchUserQuery] = useLazyQuery(FETCH_USER_QUERY, {
    client: Clients.USER,
  });
  const [fetchOrganizationByDomainQuery] = useLazyQuery(
    FETCH_ORGANIZATION_BY_DOMAIN_QUERY,
    {
      client: Clients.USER,
    },
  );
  const [fetchSignedInUserQuery] = useLazyQuery(FETCH_SIGNED_IN_USER_QUERY, {
    client: Clients.USER,
  });
  const [fetchCheckoutSession] = useLazyQuery(GET_CHECKOUT_SESSION, {
    client: Clients.USER,
    fetchPolicy: "network-only",
  });

  const [getUserSubscriptionStatusQuery] = useLazyQuery(
    GET_USER_SUBSCRIPTION_STATUS,
    {
      client: Clients.USER,
      fetchPolicy: "network-only",
    },
  );

  const [cancelSubscriptionMutation] = useMutation(
    CANCEL_SUBSCRIPTION_MUTATION,
    {
      client: Clients.USER,
    },
  );

  const [createCustomerPortalSessionMutation] = useMutation(
    CREATE_CUSTOMER_PORTAL_SESSION_MUTATION,
    {
      client: Clients.USER,
    },
  );

  const [createCheckoutSessionMutation] = useMutation(
    CREATE_CHECKOUT_SESSION_MUTATION,
    {
      client: Clients.USER,
    },
  );
  const [createUserMutation] = useMutation(CREATE_USER_MUTATION, {
    client: Clients.AUTH,
  });
  const [updateUserProfileMutation] = useMutation(
    UPDATE_USER_PROFILE_MUTATION,
    {
      client: Clients.USER,
    },
  );
  const [loading, setLoading] = useState(true);
  const [signedInUser, setSignedInUser] = useState<User | undefined>();
  const [organization, setOrganization] = useState<Organization | undefined>();

  useEffect(() => {
    setLoading(true);
    fetchOrganizationByDomain(window.location.hostname)
      .then((organization) => {
        setOrganization(organization);
        return organization;
      })
      .then(
        async (organization) =>
          await fetchSignedInUser(organization.id)
            .then((user) => setSignedInUser(user))
            .catch((e) => {
              setSignedInUser(undefined);
            }),
      )
      .catch((e) => {
        window.location.href = "https://buildql.com";
      })
      .finally(() => setLoading(false));
  }, []);

  const fetchUserById = React.useCallback(
    async (userId: string, organizationId: string): Promise<User> => {
      setLoading(true);
      return await fetchUserQuery({
        variables: {
          userId,
          organizationId,
        },
      })
        .then((result) => {
          if (!result.data?.user?.data) {
            throw new Error(
              result.data?.user?.error?.message ??
                "Error occurred while fetching user.",
            );
          }
          const user = result.data.user?.data ?? undefined;
          return {
            ...user,
            id: user?.userId,
            userName: user?.userName ?? undefined,
            firstName: user?.firstName ?? undefined,
            lastName: user?.lastName ?? undefined,
            profilePictureUrl: user?.profilePictureUrl ?? undefined,
          };
        })
        .finally(() => setLoading(false));
    },
    [],
  );

  const fetchOrganizationByDomain = React.useCallback(
    async (domain: string): Promise<Organization> => {
      return await fetchOrganizationByDomainQuery({
        variables: {
          domain,
        },
        fetchPolicy: "cache-first",
      }).then((result) => {
        if (!result.data?.organizationByDomain?.data) {
          throw new Error(
            result.data?.organizationByDomain?.error?.message ??
              "Error occurred while fetching organization.",
          );
        }
        const data = result.data.organizationByDomain.data;
        return {
          ...data,
          logoUrl: data.logoUrl ?? undefined,
          primaryColor: data.primaryColor ?? undefined,
          secondaryColor: data.secondaryColor ?? undefined,
          defaultPrompts:
            data.defaultPrompts?.map((prompt) => {
              return {
                title: prompt.title,
                description: prompt.description,
                icon: prompt.icon ?? undefined,
              };
            }) ?? [],
          customImageTag: data.customImageTag ?? undefined,
          usePaywall: data.usePaywall ?? undefined,
        };
      });
    },
    [],
  );

  const fetchSignedInUser = React.useCallback(
    async (organizationId: string): Promise<User> => {
      return await fetchSignedInUserQuery({
        variables: { organizationId },
        fetchPolicy: "network-only",
      }).then((result) => {
        const user = result.data?.signedInUser?.data;

        if (!user) {
          throw new Error(
            result.data?.signedInUser?.error?.message ??
              "Unable to fetch signed in user.",
          );
        }
        return {
          ...user,
          id: user.userId,
          firstName: user.firstName ?? undefined,
          lastName: user.lastName ?? undefined,
          userName: user.userName ?? undefined,
          profilePictureUrl: user.profilePictureUrl ?? undefined,
        };
      });
    },
    [],
  );

  const createCheckoutSession =
    React.useCallback(async (): Promise<CheckoutSession> => {
      setLoading(true);
      return await createCheckoutSessionMutation()
        .then((result) => {
          const checkoutSessionId =
            result.data?.createCheckoutSession?.data?.id;
          const clientSecret =
            result.data?.createCheckoutSession?.data?.clientSecret;

          if (!checkoutSessionId || !clientSecret) {
            throw new Error(
              result.data?.createCheckoutSession?.error?.message ??
                "Unable to create subscription checkout session.",
            );
          }
          return {
            checkoutSessionId,
            clientSecret,
          };
        })
        .finally(() => setLoading(false));
    }, []);

  const cancelSubscription = React.useCallback(async (): Promise<boolean> => {
    setLoading(true);
    return await cancelSubscriptionMutation()
      .then((result) => {
        const subscriptionCanceled = result.data?.cancelSubscription?.success;

        if (!subscriptionCanceled) {
          throw new Error(
            result.data?.cancelSubscription?.error?.message ??
              "Unable to cancel subscription",
          );
        }
        return subscriptionCanceled;
      })
      .finally(() => setLoading(false));
  }, []);

  const getUserSubscriptionStatus =
    React.useCallback(async (): Promise<SubscriptionStatus> => {
      return await getUserSubscriptionStatusQuery().then((result) => {
        const data = result.data?.userSubscription?.data;
        return {
          status: data?.status ?? Status.Inactive,
          startDate: data?.startDate ?? undefined,
          endDate: data?.endDate ?? undefined,
          cancellationDate: data?.cancellationDate ?? undefined,
        };
      });
    }, []);

  const createCustomerPortalSession =
    React.useCallback(async (): Promise<string> => {
      setLoading(true);
      return await createCustomerPortalSessionMutation()
        .then((result) => {
          const url = result.data?.createCustomerPortalSession;
          if (!url) {
            throw new Error("Unable to create customer portal session.");
          }
          return url;
        })
        .finally(() => setLoading(false));
    }, []);

  const getCheckoutSessionStatus = React.useCallback(
    async (sessionId: string): Promise<CheckoutSessionStatus> => {
      setLoading(true);
      return await fetchCheckoutSession({
        variables: {
          sessionId,
        },
      })
        .then((result) => {
          const checkoutSessionId =
            result.data?.checkoutSession?.data?.id ?? undefined;
          const checkoutSessionStatus =
            result.data?.checkoutSession?.data?.status ?? undefined;

          if (!checkoutSessionId || !checkoutSessionStatus) {
            throw new Error(
              result.data?.checkoutSession?.error?.message ??
                "Unable to get subscription checkout session status.",
            );
          }
          return {
            checkoutSessionId,
            checkoutSessionStatus,
          };
        })
        .finally(() => setLoading(false));
    },
    [],
  );

  const createUser = React.useCallback(
    async ({ email, password }: SignupDetails): Promise<User> => {
      setLoading(true);
      return await createUserMutation({
        variables: {
          email,
          password,
        },
      })
        .then((result) => {
          if (!result.data?.signUp?.user) {
            throw new Error(
              result.data?.signUp?.error?.message ?? "Unable to create user.",
            );
          }
          return {
            ...result.data.signUp.user,
            id: result.data.signUp.user.id,
            userName: result.data.signUp.user.userName ?? undefined,
          };
        })
        .finally(() => setLoading(false));
    },
    [],
  );

  const updateUserProfile = React.useCallback(
    async ({
      firstName,
      userName,
      lastName,
      profilePictureUrl,
      email,
    }: OnboardingDetailsWithProfilePictureUrl): Promise<User> => {
      setLoading(true);
      return await updateUserProfileMutation({
        variables: {
          input: {
            firstName,
            userName,
            lastName,
            profilePictureUrl,
            email,
          },
        },
      })
        .then((result) => {
          if (!result.data?.updateUserProfile?.data) {
            throw new Error(
              result.data?.updateUserProfile?.error?.message ??
                "Unable to update user.",
            );
          }
          return {
            ...result.data.updateUserProfile.data,
            id: result.data?.updateUserProfile?.data?.userId,
            firstName:
              result.data.updateUserProfile.data.firstName ?? undefined,
            lastName: result.data.updateUserProfile.data.lastName ?? undefined,
            userName: result.data.updateUserProfile.data.userName ?? undefined,
            profilePictureUrl:
              result.data.updateUserProfile.data.profilePictureUrl ?? undefined,
          };
        })
        .finally(() => setLoading(false));
    },
    [],
  );

  return {
    loading,
    signedInUser,
    organization,
    fetchUserById,
    fetchOrganizationByDomain,
    createCheckoutSession,
    getCheckoutSessionStatus,
    cancelSubscription,
    getUserSubscriptionStatus,
    createUser,
    updateUserProfile,
    createCustomerPortalSession,
  };
}
