import { faBookmark as faBookmarkTransparent } from "@fortawesome/free-regular-svg-icons";
import { faCircle, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appDomain } from "../../../config";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { User } from "../../../types/auth";
import { ILesson } from "../../../types/lesson";
import styles from "./LessonCardHorizontal.module.css";
import {
  calculateTimeAgo,
  hashString,
  selectRandomLessonImageMobile,
} from "./utils";
interface LessonCardProps {
  lesson: ILesson;
  clickUrl: string;
  showLessonEditModal?: (lessonId: string) => void;
  canBeDeleted?: boolean;
}

export const LessonCardHorizontal: React.FC<LessonCardProps> = ({
  lesson,
  clickUrl,
  showLessonEditModal,
  canBeDeleted = false,
}: LessonCardProps) => {
  const [author, setAuthor] = useState<User | undefined>();
  const { fetchUserById, organization } = useUserApiClient();

  useEffect(() => {
    fetchUserById(lesson.authorId, organization?.id ?? "")
      .then((author) => setAuthor(author))
      .catch(() => setAuthor(undefined));
  }, [lesson.authorId, organization?.id]);

  const defaultImage = React.useMemo(() => {
    const titleHash = hashString(lesson.id);
    return selectRandomLessonImageMobile(titleHash);
  }, [lesson.title]);

  const handleShareLesson = React.useCallback(async () => {
    const lessonUrl = `${appDomain}/lesson/${lesson.id}`;
    try {
      await navigator.clipboard.writeText(lessonUrl);
      alert(
        "Lesson URL successfully copied to clipboard!\n📋 Share it with anyone! 😊"
      );
    } catch (error) {
      console.error("Failed to copy lesson URL:", error);
      alert("Oops! Something went wrong. Please try again.");
    }
  }, [lesson.id, lesson.title]);

  return (
    <Link to={clickUrl} className={styles.lessonCard}>
      {/* TODO enable icons and images with backend */}
      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <img
            src={lesson.imageUrl ? lesson.imageUrl : defaultImage}
            className={styles.imgElem}
          />
          <div className={styles.mobileBookmark}>
            <div
              className={styles.bookmark}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon
                icon={faBookmarkTransparent}
                className={styles.bookmarkIcon}
              />
              32
            </div>
          </div>
        </div>
      </div>
      <div className={styles.lessonText}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {lesson.title || "Untitled Lesson"}
          </div>
        </div>
        <div className={styles.author}>
          Uploaded by
          {` ${author?.firstName ?? "Anonymous"} ${author?.lastName ?? "User"}`}
          {/* Todo get from backend */}
          <FontAwesomeIcon
            icon={faCircle}
            style={{ color: "#c1c3c2", fontSize: "6px" }}
          />
          {calculateTimeAgo(lesson.createdDate ?? "")}
        </div>
        <div
          className={styles.mobileShare}
          onClick={(e) => {
            e.preventDefault();
            void handleShareLesson();
          }}
        >
          <FontAwesomeIcon
            icon={faShareNodes}
            style={{ color: "#29834D" }}
            className={styles.share}
          />
        </div>
      </div>
    </Link>
  );
};
