import React from "react";
import { useHistory } from "react-router-dom";
import useAILessonCreatorClient from "../../../../hooks/useAILessonCreatorClient";
import ErrorComponent from "../../../common_components/ErrorComponent";
import { LoadingComponentFullScreen } from "../../../common_components/LoadingComponent";
import styles from "./LessonCreationMenu.module.css";
import { BuildExampleCard } from "../BuildExampleCard/BuildExampleCard";
import PromptInput from "../../../common_components/PromptInput/PromptInput";
import { useUserApiClient } from "../../../../hooks/useUserApiClient";

export const LessonCreationMenu: React.FC = () => {
  const history = useHistory();
  const [error, setError] = React.useState("");
  const { loading, generateSectionContentFromPrompt } =
    useAILessonCreatorClient();
  const { organization } = useUserApiClient();

  const handleSubmitPrompt = React.useCallback(async (prompt: string) => {
    await generateSectionContentFromPrompt(prompt).then((lessonId: string) => {
      history.push(`/lesson/${lessonId}`);
    });
  }, []);

  const exampleCards = React.useMemo(
    () =>
      organization?.defaultPrompts ?? [
        {
          icon: {
            type: "fontawesome",
            value: "fa-solid fa-list-check",
          },
          title: "A to-do list app",
          description:
            "Generate a lesson to teach how to build a tod-do list web application.",
        },
        {
          icon: {
            type: "fontawesome",
            value: "fa-solid fa-cloud-rain",
          },
          title: "Weather app",
          description:
            "Generate a lesson to teach how to build a weather application.",
        },
        {
          icon: {
            type: "fontawesome",
            value: "fa-solid fa-calculator",
          },
          title: "Simple calculator",
          description:
            "Generate a lesson to teach how to build a simple calculator web application.",
        },
      ],
    [organization?.defaultPrompts]
  );

  return (
    <div className={styles.container}>
      {loading && <LoadingComponentFullScreen />}
      {error && (
        <ErrorComponent
          title="Lesson generation failed."
          close={() => setError("")}
        >
          {error}
        </ErrorComponent>
      )}
      <div className={styles.aiContainer}>
        <div className={styles.aiBody}>
          <div className={styles.gridTitle}>
            Hello, what are you building{" "}
            <span
              className={styles.todayText}
              style={{ color: organization?.secondaryColor }}
            >
              today?
            </span>
          </div>

          <div className={styles.inputGroup}>
            <PromptInput
              handleSendMessage={(message) => {
                void handleSubmitPrompt(message);
              }}
              className={styles.promptInput}
              placeholder={"Ask " + (organization?.name ?? "") + "..."}
            />

            <div className={styles.examples}>
              {exampleCards.map((card, index) => (
                <BuildExampleCard
                  key={index}
                  prompt={card}
                  runFunction={async (prompt) =>
                    await handleSubmitPrompt(prompt)
                  }
                  className={styles.exampleCard}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
