import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import styles from "./CheckSubscriptionComponent.module.css";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { Status } from "../../../__generated__/graphql";
import { LoadingComponentFullScreen } from "../LoadingComponent";

interface CheckSubscriptionComponentProps {
  children: React.ReactNode;
}

export default function CheckSubscriptionComponent({
  children,
}: CheckSubscriptionComponentProps): React.JSX.Element {
  const {
    getUserSubscriptionStatus,
    organization,
    loading: loadingUser,
  } = useUserApiClient();

  const [loading, setLoading] = React.useState(true);
  const [isUserSubscribed, setIsUserSubscribed] = React.useState(false);

  useEffect(() => {
    if (!loadingUser && organization) {
      setLoading(true);
      getUserSubscriptionStatus(organization.id)
        .then((data) => {
          if (data.status === Status.Active) {
            setIsUserSubscribed(true);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [organization?.id, loadingUser]);

  return (
    <div className={styles.container}>
      {loading ? (
        <LoadingComponentFullScreen />
      ) : !isUserSubscribed && organization?.usePaywall ? (
        <Redirect to="/subscriptions" />
      ) : (
        children
      )}
    </div>
  );
}
