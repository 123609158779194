import { faBookmark as faBookmarkTransparent } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faPen,
  faShareNodes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appDomain } from "../../../config";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { User } from "../../../types/auth";
import { ILesson } from "../../../types/lesson";
import styles from "./LessonCardVertical.module.css";
import {
  calculateTimeAgo,
  hashString,
  selectRandomLessonImageDesktop,
} from "./utils";
import useLessonApiClient from "../../../hooks/useLessonApiClient";

interface LessonCardProps {
  lesson: ILesson;
  clickUrl: string;
  showLessonEditModal?: (lessonId: string) => void;
  canBeDeleted?: boolean;
}

export const LessonCardVertical: React.FC<LessonCardProps> = ({
  lesson,
  clickUrl,
  showLessonEditModal,
  canBeDeleted = false,
}: LessonCardProps) => {
  const [author, setAuthor] = useState<User | undefined>();

  const { fetchUserById, organization } = useUserApiClient();
  const { deleteLesson } = useLessonApiClient();

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();
      e.stopPropagation();

      deleteLesson(lesson.id)
        .then(() => {
          console.log("Lesson successfully deleted");
        })
        .catch((error) => {
          console.error("Error deleting lesson:", error);
        });
    },
    [deleteLesson, lesson.id]
  );

  const formatDuration = useCallback((duration: number): string => {
    if (duration < 60) {
      return `${duration} second${duration === 1 ? "" : "s"}`;
    }
    if (duration < 3600) {
      return `${Math.round(duration / 60)} minute${
        Math.round(duration / 60) === 1 ? "" : "s"
      }`;
    }
    const hours = Math.round(duration / 3600);
    return `${hours} hour${hours === 1 ? "" : "s"}`;
  }, []);

  useEffect(() => {
    fetchUserById(lesson.authorId, organization?.id ?? "")
      .then((author) => setAuthor(author))
      .catch(() => setAuthor(undefined));
  }, [lesson.authorId, organization?.id]);

  const defaultImage = React.useMemo(() => {
    const titleHash = hashString(lesson.title);
    return selectRandomLessonImageDesktop(titleHash);
  }, [lesson.title]);

  const handleShareLesson = React.useCallback(async () => {
    const lessonUrl = `${appDomain}/lesson/${lesson.id}`;
    try {
      await navigator.clipboard.writeText(lessonUrl);
      alert(
        "Lesson URL successfully copied to clipboard!\n📋 Share it with anyone! 😊"
      );
    } catch (error) {
      console.error("Failed to copy lesson URL:", error);
      alert("Oops! Something went wrong. Please try again.");
    }
  }, [lesson.id, lesson.title]);

  return (
    <Link to={clickUrl} className={styles.lessonCard}>
      {/* TODO enable icons and images with backend */}
      <section className={styles.imageContainer}>
        <img
          src={lesson.imageUrl ? lesson.imageUrl : defaultImage}
          className={styles.imgElem}
        />
        <div className={styles.imageIcons}>
          <div className={styles.leftContainer}>
            {showLessonEditModal && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  showLessonEditModal(lesson.id);
                }}
                className={styles.editIcon}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
            {canBeDeleted && (
              <button
                onClick={handleDeleteClick}
                className={styles.lessonDeleteIcon}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
          </div>
          <div className={styles.rightIconsContainer}>
            <div
              className={styles.bookmark}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon
                icon={faBookmarkTransparent}
                className={styles.bookmarkIcon}
              />
              {/** Todo get enrolled count */}
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                void handleShareLesson();
              }}
            >
              <FontAwesomeIcon
                icon={faShareNodes}
                style={{ color: "#29834D" }}
                className={styles.share}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.lessonDetails}>
        <div className={styles.title}>{lesson.title || "Untitled Lesson"}</div>
        <div className={styles.author}>
          Uploaded by
          {` ${author?.firstName ?? "Anonymous"} ${author?.lastName ?? "User"}`}
          {/* Todo get from backend */}
          <FontAwesomeIcon
            icon={faCircle}
            style={{ color: "#c1c3c2", fontSize: "6px" }}
          />
          {calculateTimeAgo(lesson.createdDate ?? "")}
        </div>
        <div
          className={styles.mobileShare}
          onClick={(e) => {
            e.preventDefault();
            void handleShareLesson();
          }}
        >
          <FontAwesomeIcon
            icon={faShareNodes}
            style={{ color: "#29834D" }}
            className={styles.share}
          />
        </div>

        <span className={styles.lineDivider}></span>

        <div className={styles.infoContainer}>
          <div className={styles.learnersContainer}>
            <div className={styles.imageStack}></div>
          </div>
          <div className={styles.iconWithText}>
            <div
              className={styles.textBoxContainer}
              style={{
                background: `linear-gradient(180deg, ${organization?.secondaryColor ?? "#66cc8d"} 0%, ${organization?.primaryColor ?? "#55dddd"} 66.25%)`,
              }}
            >
              <div className={styles.textBox}>
                {lesson.duration
                  ? formatDuration(lesson.duration)
                  : "0 minutes"}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};
