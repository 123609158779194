import React from "react";
import HeaderComponent from "../../common_components/DashboardHeaderComponent";
import styles from "./Layout.module.css";
import ErrorComponent from "../../common_components/ErrorComponent";
import { useViewContext } from "../../common_components/ChamIDE/contexts/ViewContext/ViewContext";
import { ViewState } from "../../common_components/LessonLayout/types";
import {
  DeviceType,
  useResponsiveness,
} from "../../../hooks/useResponsiveness";
import { ClarificationsView } from "../../Lesson/components/Clarifications";
import { SideBar } from "../Sidebar/Sidebar";

interface ILayoutProps {
  children: JSX.Element;
}

function Layout(props: ILayoutProps): React.JSX.Element {
  const [error, setError] = React.useState<string>("");
  const { currentView } = useViewContext();
  const { deviceType } = useResponsiveness();

  return (
    <>
      {deviceType === DeviceType.Desktop ? (
        <div className={styles.container}>
          <HeaderComponent dark />
          <div className={styles.mainWindow}>
            {error && (
              <div className={styles.errorComponentContainer}>
                <ErrorComponent
                  title="Something went wrong"
                  close={() => setError("")}
                >
                  {error}
                </ErrorComponent>
              </div>
            )}
            <div className={styles.clarifications}>
              <ClarificationsView />
            </div>

            <div className={styles.contentEditor}>{props.children}</div>
          </div>
        </div>
      ) : (
        <div className={styles.containerMobile}>
          <HeaderComponent dark />
          <div className={styles.mainWindow}>
            <SideBar setError={setError} />

            <div
              className={`${styles.contentEditor} ${
                currentView === ViewState.Lesson ||
                currentView === ViewState.Github ||
                currentView === ViewState.ClarificationChat
                  ? ""
                  : styles.hidden
              }`}
            >
              {props.children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Layout;
