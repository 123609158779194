import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { Organization } from "../../../types/organization";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

interface OrganizationContextData {
  loading: boolean;
  organization?: Organization;
}

const OrganizationContext = createContext<OrganizationContextData>({
  organization: undefined,
  loading: true,
});

export const OrganizationProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { fetchOrganizationByDomain } = useUserApiClient();
  const [organization, setOrganization] = useState<Organization>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    fetchOrganizationByDomain(window.location.hostname)
      .then(setOrganization)
      .finally(() => setLoading(false));
  }, [fetchOrganizationByDomain]);

  return (
    <OrganizationContext.Provider value={{ organization, loading }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = (): OrganizationContextData => {
  return useContext(OrganizationContext);
};
