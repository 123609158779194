import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLessonApiClient from "../../hooks/useLessonApiClient";
import { useUserApiClient } from "../../hooks/useUserApiClient";
import { PaginatedLesson } from "../../types/lesson";
import DashboardComponent from "../common_components/DashboardComponent";
import LoggedOutDashboardComponent from "../common_components/LoggedOutDashboardComponent";
import ProfileContent from "./components/content";
import ProfileHeader from "./components/header";
import styles from "./UserProfile.module.css";
import { UserDetails } from "./components/user-details";
import ErrorComponent from "../common_components/ErrorComponent";
import { LoadingComponentFullScreen } from "../common_components/LoadingComponent";

export interface AccountDetails {
  firstName: string;
  lastName: string;
}

export interface RouterParams {
  userId: string;
}

export default function UserProfile(): JSX.Element {
  const { userId } = useParams<RouterParams>();
  const [paginatedLessons, setPaginatedLessons] = useState<PaginatedLesson>({
    hasNext: false,
    lessons: [],
    totalElements: 0,
    totalPages: 0,
  });
  const {
    fetchLessonsByAuthorId,
    fetchPaginatedLessonsByAuthorId,
    fetchLesson,
  } = useLessonApiClient();
  const {
    fetchUserById,
    signedInUser,
    organization,
    loading: loadingUser,
  } = useUserApiClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async (): Promise<void> => {
      if (!loadingUser && signedInUser && organization) {
        try {
          const paginatedLesson = await fetchPaginatedLessonsByAuthorId(
            signedInUser.id,
            organization.id,
            currentPage,
            10,
          );
          setPaginatedLessons(paginatedLesson);
        } catch (error) {
          console.error("Error fetching lessons:", error);
          setError("Failed to fetch lessons.");
        }
      }
    };

    fetchData()
      .catch((error) => console.error("Error in fetchData:", error))
      .finally(() => setIsLoading(false));
  }, [
    signedInUser,
    userId,
    currentPage,
    organization,
    fetchLessonsByAuthorId,
    fetchUserById,
    fetchLesson,
  ]);

  const Dashboard = ({
    children,
  }: {
    children: React.JSX.Element;
  }): React.JSX.Element => {
    return (
      <>
        {isLoading && <LoadingComponentFullScreen />}
        {signedInUser ? (
          <DashboardComponent>{children}</DashboardComponent>
        ) : (
          <LoggedOutDashboardComponent>{children}</LoggedOutDashboardComponent>
        )}
        ;
      </>
    );
  };

  return (
    <Dashboard>
      <>
        <div className={styles.container}>
          {signedInUser && (
            <>
              {error && (
                <div className={styles.errorComponentContainer}>
                  <ErrorComponent
                    title="Could not update profile"
                    close={() => setError("")}
                  >
                    {error}
                  </ErrorComponent>
                </div>
              )}
              <ProfileHeader />
              <div className={styles.profileContent}>
                <UserDetails
                  user={signedInUser}
                  lessonCount={paginatedLessons.totalElements}
                  setError={setError}
                  setIsLoading={setIsLoading}
                  signedInUser={signedInUser ?? undefined}
                />
                <ProfileContent
                  organization={organization}
                  paginatedLessons={paginatedLessons}
                  user={signedInUser}
                  signedInUser={signedInUser ?? undefined}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </>
          )}
        </div>
      </>
    </Dashboard>
  );
}
