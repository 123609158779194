import React from "react";

interface LoginProviderData {
  email?: string;
  setEmail: (email: string) => void;
}

export const LoginContext = React.createContext<LoginProviderData>({
  email: undefined,
  setEmail: () => undefined,
});

export function LoginProvider({
  children,
}: {
  children: JSX.Element;
}): React.ReactNode {
  const [email, setEmail] = React.useState<string>();
  return (
    <LoginContext.Provider value={{ email, setEmail }}>
      {children}
    </LoginContext.Provider>
  );
}

export function useLoginProvider(): LoginProviderData {
  return React.useContext(LoginContext);
}
