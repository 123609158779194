import React, { useEffect, useState } from "react";
import ModalOverlayComponent from "../../../common_components/ModalOverlayComponent";
import styles from "./LessonManagementModal.module.css";
import ErrorComponent from "../../../common_components/ErrorComponent";
import { ILesson, LessonManagementData } from "../../../../types/lesson";
import useLessonApiClient from "../../../../hooks/useLessonApiClient";
import { useHistory } from "react-router-dom";
import { LoadingComponentFullScreen } from "../../../common_components/LoadingComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faX } from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { uploadPublicAsset } from "../../../../rest-clients/FileService";

interface LessonManagementModalProps {
  lesson?: ILesson;
  setLesson?: (lesson: ILesson) => void;
  closeModal: () => void;
}

export default function LessonManagementModal({
  lesson,
  setLesson,
  closeModal,
}: LessonManagementModalProps): React.JSX.Element {
  const history = useHistory();
  const [error, setError] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [lessonData, setLessonData] = useState<LessonManagementData>({
    title: lesson?.title ?? "",
    imageUrl: "",
  });
  const apiClient = useLessonApiClient();

  const createLesson = React.useCallback((data: LessonManagementData) => {
    apiClient
      .createLesson(data)
      .then((lesson) => {
        history.push(`/lesson/${lesson.id}`);
      })
      .catch((e) =>
        setError(e.message ?? "Error occured while creating lesson.")
      );
  }, []);

  const updateLesson = React.useCallback((data: LessonManagementData) => {
    apiClient
      .updateLesson(lesson?.id ?? "", data)
      .then((updatedLesson) => {
        setLesson?.(updatedLesson);
        closeModal();
      })
      .catch((e) =>
        setError(e.message ?? "Error occurred while updating lesson.")
      );
  }, []);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsImageUploading(true);
    const data = new FormData();
    data.append("file", e.target.files?.[0] as File);
    uploadPublicAsset(data)
      .then((response) => {
        setLessonData({
          ...lessonData,
          imageUrl: response.assetUrl,
        });
      })
      .catch((e) =>
        setError(e.message ?? "Error occured while uploading image.")
      )
      .finally(() => setIsImageUploading(false));
  };

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    };
  }, []);

  if (apiClient.loading) {
    console.log("LessonManagementModal");
  }

  return (
    <>
      <ModalOverlayComponent onClick={closeModal}>
        {apiClient.loading ? (
          <LoadingComponentFullScreen />
        ) : (
          <div
            className={styles.container}
            onClick={(event) => event.stopPropagation()}
          >
            {error && (
              <ErrorComponent title="Error occured!" close={() => setError("")}>
                {error}
              </ErrorComponent>
            )}
            <div className={styles.contentDiv}>
              <button className={styles.modalCloseButton} onClick={closeModal}>
                <FontAwesomeIcon icon={faX} />
              </button>
              <div className={styles.iconContainer}>
                {lesson ? (
                  <FontAwesomeIcon icon={faEdit} className={styles.icon} />
                ) : (
                  <FontAwesomeIcon
                    icon={faSquarePlus}
                    className={styles.icon}
                  />
                )}
              </div>
              <h2>{lesson ? "Edit Lesson" : "Create Lesson"}</h2>
              <p>Enter the lesson information below to continue.</p>
              <div className={styles.content}>
                <label>Lesson Title</label>
                <input
                  type="text"
                  className={styles.titleInput}
                  placeholder="Enter lesson title..."
                  onChange={(e) =>
                    setLessonData({
                      ...lessonData,
                      title: e.target.value,
                    })
                  }
                  value={lessonData.title}
                />
              </div>
              <div className={styles.content}>
                <label>Lesson Thumbnail</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <div className={styles.thumbnailPreview}>
                  {isImageUploading ? (
                    "Uploading image..."
                  ) : lessonData.imageUrl ? (
                    <img src={lessonData.imageUrl} />
                  ) : (
                    <p>No image selected.</p>
                  )}
                </div>
              </div>
              <button
                className={`${styles.createCourseButton}`}
                onClick={() =>
                  lesson ? updateLesson(lessonData) : createLesson(lessonData)
                }
                disabled={isImageUploading}
              >
                {lesson ? "Update Lesson" : "Create Lesson"}
              </button>
            </div>
          </div>
        )}
      </ModalOverlayComponent>
    </>
  );
}
