import React from "react";
import DashboardComponent from "../common_components/DashboardComponent";
import GridComponent from "../common_components/GridComponent";
import styles from "./SavedLessonsDashboard.module.css";

export default function SavedLessonsView(): JSX.Element {
  return (
    <DashboardComponent>
      {
        <div className={styles.container}>
          <div className={styles.gridTitle}>Saved Lessons</div>
          <GridComponent boxes={[]} />
        </div>
      }
    </DashboardComponent>
  );
}
