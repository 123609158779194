import React, { useEffect, useState } from "react";
import styles from "./SubscriptionManagementModal.module.css";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faX } from "@fortawesome/free-solid-svg-icons";
import ErrorComponent from "../../common_components/ErrorComponent";
import ModalOverlayComponent from "../../common_components/ModalOverlayComponent";
import { SubscriptionStatus } from "../../../types/auth";
import { Status } from "../../../__generated__/graphql";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

interface SubscriptionManagementModalProps {
  subscription: SubscriptionStatus;
  closeModal: () => void;
}

export default function SubscriptionManagementModal({
  subscription,
  closeModal,
}: SubscriptionManagementModalProps): React.JSX.Element {
  const history = useHistory();
  const { signedInUser, cancelSubscription, loading, organization } =
    useUserApiClient();
  const [error, setError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>();
  const isUserSubscribed = subscription.status === Status.Active;
  const handleSubscribeClick = React.useCallback(() => {
    history.push("/subscription/checkout");
  }, []);

  const handleUnsubscribeClick = React.useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  const handleCancelSubscription = React.useCallback(async () => {
    await cancelSubscription();
    setShowConfirmationModal(false);
    closeModal();
    history.push(`/profile/${signedInUser?.id ?? ""}`);
  }, [signedInUser, organization?.id]);

  const handleUndoCancelSubscription = React.useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <ModalOverlayComponent
        onClick={closeModal}
        className={styles.modalParent}
      >
        <div
          className={styles.container}
          onClick={(event) => event.stopPropagation()}
        >
          {error && (
            <ErrorComponent title="Error occured!" close={() => setError("")}>
              {error}
            </ErrorComponent>
          )}
          <div className={styles.contentDiv}>
            <button className={styles.modalCloseButton} onClick={closeModal}>
              <FontAwesomeIcon icon={faX} />
            </button>

            <div>
              <h2 className={styles.title}>Manage Subscription</h2>
              <p>
                Your current subscription plan is{" "}
                <span
                  className={styles.subscriptionPlan}
                  style={{ color: organization?.primaryColor }}
                >
                  {isUserSubscribed ? "Premium" : "Basic"}
                </span>
              </p>

              <div className={styles.content}>
                <div>Subscription Status: {subscription?.status}</div>
                {subscription.startDate && (
                  <div>Subscription Start Date: {subscription.startDate}</div>
                )}
                {subscription.endDate && (
                  <div>Subscription End Date: {subscription.endDate}</div>
                )}
                {subscription.status === Status.Active &&
                  (!subscription.cancellationDate ? (
                    <div>Renewal Date: {subscription.endDate}</div>
                  ) : (
                    <div>
                      Cancellation Date: {subscription.cancellationDate}
                    </div>
                  ))}
              </div>
              {/* TODO: Dynamically get these */}
              <div className={styles.features}>
                <div className={styles.subscriptionFeaturesTitle}>
                  Features:
                </div>
                {isUserSubscribed ? (
                  <div className={styles.subscriptionFeatures}>
                    <li>✅ ChamIDE Access</li>
                    <li>✅ Lesson Access</li>
                    <li>✅ Unlimited Usage</li>
                    <li>✅ 24/7 Support</li>
                    <li>✅ Access to all features</li>
                  </div>
                ) : (
                  <div className={styles.subscriptionFeatures}>
                    <li>✅ Access to Lesson Explorer</li>
                    <li>❌ ChamIDE Access</li>
                    <li>❌ Lesson Access</li>
                    <li>❌ Unlimited Usage</li>
                    <li>❌ 24/7 Support</li>
                    <li>❌ Access to all features</li>
                  </div>
                )}
              </div>
            </div>

            <button
              className={`${isUserSubscribed ? styles.cancelButton : styles.subscribeButton}`}
              onClick={() =>
                isUserSubscribed
                  ? handleUnsubscribeClick()
                  : handleSubscribeClick()
              }
              style={{ backgroundColor: organization?.primaryColor }}
            >
              {isUserSubscribed ? "Cancel Subscription" : "Subscribe"}
            </button>
          </div>
        </div>
      </ModalOverlayComponent>
      {showConfirmationModal && (
        <ModalOverlayComponent className={styles.modalParent}>
          <div
            className={styles.confirmationContainer}
            onClick={(event) => event.stopPropagation()}
          >
            <div className={styles.contentDiv}>
              <button className={styles.modalCloseButton} onClick={closeModal}>
                <FontAwesomeIcon icon={faX} />
              </button>

              <div>
                <h2 className={styles.title}>Cancel Subscription</h2>
                <p>
                  Are you sure you want to cancel your{" "}
                  <span className={styles.subscriptionPlan}>
                    {isUserSubscribed ? "Premium" : "Basic"}
                  </span>{" "}
                  plan
                </p>
              </div>
              <div className={styles.buttons}>
                <button
                  className={`${styles.undoFinalButton}`}
                  onClick={() => handleUndoCancelSubscription()}
                  disabled={loading}
                >
                  No
                </button>
                <button
                  className={`${styles.cancelFinalButton}`}
                  onClick={() => {
                    void handleCancelSubscription();
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className={"fa-spin"}
                      color="1b5a37"
                      style={{ padding: "5px" }}
                    />
                  ) : (
                    "Yes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </ModalOverlayComponent>
      )}
    </>
  );
}
