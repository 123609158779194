import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "../LessonCreation.module.css";
import { ActionAgentButton } from "../../Lesson/components/ActionAgent";
import { useLessonId } from "../../common_components/LessonContext/LessonContext";

interface IPageMenuProps {
  currentPageId?: string;
  currentPageIndex: number;
  totalPages: number;
  updateCurrentPageIndex: (newIndex: number) => void;
  addPage?: () => void;
}

function PageMenu({
  currentPageId,
  currentPageIndex,
  totalPages,
  updateCurrentPageIndex,
  addPage,
}: IPageMenuProps): React.JSX.Element {
  const lessonId = useLessonId();
  return (
    <div className={styles.pageMenu}>
      <div className={styles.pagesNavigation}>
        <button
          onClick={() => updateCurrentPageIndex(currentPageIndex - 1)}
          className={styles.pageNavigationArrow}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <span className={styles.pagesCount}>
          {currentPageIndex + 1}
          <span> of </span>
          {totalPages}
        </span>
        <button
          onClick={() => updateCurrentPageIndex(currentPageIndex + 1)}
          className={styles.pageNavigationArrow}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      <div>
        {addPage && (
          <button onClick={() => addPage()} className={styles.addPageButton}>
            Add Page
          </button>
        )}
        {lessonId && currentPageId && (
          <ActionAgentButton lessonId={lessonId} pageId={currentPageId} />
        )}
      </div>
    </div>
  );
}

export default PageMenu;
