import React, { useState } from "react";
import ResetPasswordForm from "./components/PasswordResetForm";
import FooterComponent from "../../common_components/FooterComponent";
import styles from "./PasswordReset.module.css";
import { useHistory, useLocation } from "react-router-dom";
import useAuthApiClient from "../../../hooks/useAuthApiClient";
import ErrorComponent from "../../common_components/ErrorComponent";
import MainHeaderComponent from "../../common_components/MainHeaderComponent";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

const useQuery = (): URLSearchParams => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

function PasswordReset(): React.JSX.Element {
  const query = useQuery();
  const history = useHistory();

  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [error, setError] = useState("");
  const { resetPassword } = useAuthApiClient();
  const { organization } = useUserApiClient();

  const submit = (newPassword: string): void => {
    resetPassword({ newPassword, token: query.get("token") ?? "" })
      .then(() => {
        setIsPasswordReset(true);
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      })
      .catch((e: Error) => setError(e.message ?? "Unable to reset password."));
  };

  const headerElements = (
    <>
      <span className={styles.signupButtonLeadingText}>
        New to {organization?.name}?
      </span>
      <a href="/signup" className={styles.signupButton}>
        Create an account
      </a>
    </>
  );

  return (
    <div className={styles.container}>
      <MainHeaderComponent headerElements={headerElements} />
      <div className={styles.innerLoginDiv}>
        {error && (
          <ErrorComponent
            title="Password Reset Failed"
            close={() => setError("")}
          >
            {error}
          </ErrorComponent>
        )}
        {isPasswordReset ? (
          <>
            <div className={styles.sucessText}>Password reset successful</div>
            <div className={styles.sucessText}>
              Redirecting to login page...
            </div>
          </>
        ) : (
          <ResetPasswordForm submit={submit} />
        )}
      </div>
      <FooterComponent />
    </div>
  );
}

export default PasswordReset;
